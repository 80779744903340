
    .referral-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      max-width: 600px;
      height: 100vh;
      overflow-y: auto;
      margin: 0 auto;
        color: #ffffff;
        background: url('../assets/images/friends.png');
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: linear-gradient(180deg, #1e1e2f, #000);
      border-radius: 8px;
      padding-bottom: 120px;
    }

    .title-img{
      width: 500px;
      height: 250px;
      padding: 5px;
      position: absolute;
      margin: 0 auto;
    }

    .logot{
      width: 30px;
    }

    @media (max-width:767px) {
      .title-img{
        width: 100%;
        height: 350px;
        padding: 5px;
        position: absolute;
        margin: 0 auto;
      }
      
    }
    .title {
      font-size: 2rem;
      color: #333;
      margin-bottom: 20px;
      z-index: 100;
    }

    .intro-text{
        border:1px solid rgb(163, 32, 196);
        width: 90%;
        padding: 15px;
        box-shadow: 2px 2px 2px 2px black;
        z-index: 100;
        background-color: rgb(5, 5, 4);
        margin-top: 120px;
    }

    .referral-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }

    .referral-info {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }

    .copy-icon {
      cursor: pointer;
      margin-left: 10px;
      color: #6c757d;
    }

    .referral-buttons {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: 20px;
    }

    .copy-button,
    .invite-button {
      width: 200px;
      margin: 10px;
      background: #8406a3;
      color: rgb(247, 247, 247);
      z-index: 1000;
    }

    .ref-bonus{
        display: flex;
        justify-content: space-around;
        width: 100%;
        gap: 10px;
    }

    .referral-stats {
      margin-top: 20px;
      width: 100%;
      text-align: center;
      padding-bottom: 20px;
    }

    .stats-item {
      background: #1f1f31;
      font-size: 1rem;
      font-weight: bold;
      color: #7428a7;
      padding: 15px;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      margin-top: 10px;
    }

    .referral-bonus {
      margin-top: 30px;
      text-align: center;
      padding: 20px;
      background: #1f1f31;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    .bonus-amount {
      font-size: 1rem;
      font-weight: bold;
      color: #7428a7;
      margin-top: 10px;
      background: #1f1f31;
      padding: 15px;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      margin-top: 10px;
    }

    @media (max-width: 768px) {

      
    .intro-text{
      border:1px solid rgb(163, 32, 196);
      width: 90%;
      padding: 15px;
      box-shadow: 2px 2px 2px 2px black;
      z-index: 100;
      background-color: rgb(5, 5, 4);
      margin-top: 120px;
    
  }

      .referral-container {
        padding: 10px;
        padding-bottom: 170px;
      }

     

      .copy-button,
      .invite-button {
        width: 40%;
        margin: 5px 0;
     
      }
    }
  