.referral-contest {
  background: linear-gradient(180deg, #1e1e2f, #000);
  color: #fff;
  padding: 20px;
  border-radius: 10px;
height: 100vh;
  width: 100%;
  margin: 0 auto;
padding-bottom: 80px;
overflow-y: auto;


}

.ref{
  text-align: center;
}
.contest-info {
  margin: 20px 0;
  border-radius: 10px;
  background: #1f1f31;
  color: #dcd7f6;
  border: 2px solid #8c52ff;
  box-shadow: 2px 2px 2px 2px black;
  padding-bottom: 80px;

}

.encouragement {
  color: #ff5722;
  text-align: center;
}

.target{
  width: 100%;
  background: goldenrod;
  color: #000;
  text-align: center;
  font-size: 1.5rem;
}

.cont{
  padding: 10px;
}
.con{
  background: rgb(15, 15, 14);
  font-size: 2rem;
  color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 20px;
  border: 2px solid purple;
  max-width: 400px;
  text-align: left;
}
.contest-img{
  float: right;
}
.contest-img img{
  width: 250px;
  margin: 20px;
  margin-top: -30px;
  padding: 20px;
}

.reward{
  text-align: center;
  text-decoration: wavy;
}
.claim-button {
  background-color: rgb(0, 0, 0);
  color: #8e1faf;
  padding: 10px 20px;
  border: none;
  width: 60%;
  margin: 0 auto;
  margin-left: 20px;
  border-radius: 5px;
  border: 1px solid rgb(123, 42, 189);
  cursor: pointer;
  position: absolute;

}

.claim-button:hover {
  background-color: rgb(100, 11, 160);
}

.admin-controls {
  margin-top: 20px;
  background: #000000;
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;


}

.admin-controls input {
  margin-right: 10px;
  padding: 5px;
  width: 100%;
  background: #000;
  color: white;
}

.admin-controls button {
  background-color: #f44336;
  color: #fff;
 width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 5px;
}

.admin-controls button:hover {
  background-color: #d32f2f;
}

.claim-message {
  margin-top: 10px;
  color: #00e676;
}



@media (max-width:767px) {

    .admin-controls {
        margin-top: 20px;
        background: #000000;
        padding: 10px;
        padding-bottom: 20px;
        height: 100%;
      
      
      }
    .contest-img{
      float: none;
    }
    .contest-img img{
      width: 250px;
      padding: 0;
      display: block;
      margin: 0 auto;
    }

    .admin-controls input {
        margin: 0 auto;
        padding: 5px;
        width: 100%;
      }
      .con{
        font-size: 1.5rem;
        text-align: center;
      }

      .claim-button {
        background-color: rgb(0, 0, 0);
        color: #ffffff;
        padding: 10px 20px;
        border: none;
        width: 80%;
        height: 50px;
        margin: 0 auto;
        border-radius: 5px;
        border: 1px solid rgb(123, 42, 189);
        cursor: pointer;
        position:relative;
      
        
      }

}