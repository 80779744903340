.timeline-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #000000; /* Dark background */
    color: white; /* Text color */
    overflow-y: auto;
    height: 100vh;
    padding-bottom: 120px;
  }
  
  h2 {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
    color: #8c00ff; /* Purple color for heading */
  }
  
  .timeline {
    position: relative;
    padding-left: 20px;
    padding-bottom: 70px;
  }
  
  .timeline-item {
    position: relative;
    margin-bottom: 30px;
  }
  
  .timeline-marker {
    position: absolute;
    left: -10px;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #121212; /* Black border */
    transition: background-color 0.3s;
  }
  
  .timeline-content {
    margin-left: 40px;
    padding: 10px;
    border-left: 2px solid #8c00ff; /* Purple border */
    background-color: #1f1f1f; /* Slightly lighter dark background for content */
  }
  
  .timeline-content h3 {
    font-size: 18px;
    margin: 0 0 5px 0;
    color: #8c00ff; /* Purple color for title */
  }
  
  .timeline-content p {
    font-size: 14px;
    margin: 0 0 10px 0;
  }
  
  .timeline-content small {
    font-size: 12px;
    color: #bbb; /* Light gray text for date */
  }
  
  /* Status-specific marker colors */
  .completed .timeline-marker {
    background-color: #8c00ff; /* Purple for completed */
  }
  
  .ongoing .timeline-marker {
    background-color: #6a1b9a; /* Darker purple for ongoing */
  }
  
  .upcoming .timeline-marker {
    background-color: #4a148c; /* Dark purple for upcoming */
  }
  