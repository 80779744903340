/* Roadmap.css */
.roadmap-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    position: relative;
  }
  
  .phase {
    position: relative;
    padding: 20px 0;
    margin-left: 4px;
    border-left: 2px dashed #ddd;
    
    
  }
  
  .phase-circle {
    position: absolute;
    left: -20px;
    width: 20px;
    height: 20px;
    background-color: #b300a4;
    border-radius: 50%;
    border: 2px dashed #fff;
    top: 0;
  }
  
  .phase-content {
    padding-left: 20px;
    background: rgb(73, 2, 95);
    margin: 10px 0;
    padding: 10px;
    border-radius: 30px 0;
    border: 1px solid white;
  }
  
  .phase-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .step {
    margin-bottom: 20px;
  }
  
  .step-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .description {
    font-size: 16px;
  }
  
  .user-role {
    font-style: italic;
    color: grey;
  }
  