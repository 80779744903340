.grid-widget {
  display: flex;
  flex-direction: column; /* Arrange items in a vertical column */
  justify-content: flex-start; /* Align items at the top */
  align-items: flex-end; /* Align the column to the right */
  position: absolute;
  top: 150px; /* Adjust this for vertical alignment */
  right: 400px; /* Add space from the right edge */
  width: auto; /* Automatically adjust width */
  /* Add padding for content spacing */
  border-radius: 30px;
  z-index: 1000;

  
}

.grid-item {
  background: rgba(0, 0, 0, 0.411);
 
  width: 80px; /* Auto-adjust based on content */
  border: none;
  margin: 5px; /* Space between items vertically */
  border-radius: 8px;
  padding: 2px;
  text-align: center;
  text-decoration: none;
  font-size: 0.8rem;
  color: rgb(255, 255, 255);
}

.grid-item .icon {
padding: 0;
}

.grid-item-image {
  width: 50px; /* Adjust size as needed */
  height: 50px;
  object-fit: contain;
}

.grid-item .name {
  color: white;
}



.grid-item .badge {
  background-color: red;
  color: white;
  font-size: 10px;
  border-radius: 50%;
  padding: 2px 6px;
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 992px) {
  .grid-widget {
    top: 150px; /* Adjust for smaller screens */
    right: 10px;
    position: fixed; /* Reduce margin for smaller screens */
  }

  .grid-item {
    width: 100%; /* Adjust width for smaller screens */
    margin:  5px;
    padding: 2px;
    width: 100px;
    height: 80px; 
  }

  .grid-item-image {
    width: 40px; /* Smaller image size */
    height: 40px;
  }
}
