/* General Page Styling */
.airdrop-page {
background: url('../assets/images/tokenbg.png');
    color: #dcd7f6;
    background-image: linear-gradient(180deg, #1e1e2f, #000);
    text-align: center;
    padding: 20px;
    height: 100vh;
    font-family: 'Arial', sans-serif;
    overflow: auto;
    padding-top: 80px;
    padding-bottom: 120px;
 
  }
  
  /* Title and Subtitle */
  .title {
    font-size: 2.5rem;
    color: #8c52ff;
    margin-bottom: 10px;
  }
  
  .subtitle { 
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    color: #ffffff;
    margin-top: 50px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  /* Connect Wallet Button */
  .connect-wallet-btn {
    background-color: #8c52ff;
    display: block;
    margin: 30px auto;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.2s ease-in-out;
  }
  
  .connect-wallet-btn:hover {
    background-color: #6e3bcc;
    transform: scale(1.05);
  }
  
  /* Wallet Info Section */
  .wallet-info {
    margin-top: 20px;
    background: #292942;
    padding: 15px;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
    text-align: left;
    color: #ffffff;
  }
  
  /* Phase Info Section */
  .phase-info {
    margin-top: 40px;
    color: #ffffff;
    text-align: center;
  }
  
  .cex-partners {
    margin-top: 20px;
    border: 2px solid rgb(132, 10, 212);
    padding: 10px;
    background: #000;
  }
  
  .cex-partners-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 15px;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  .cex-partner {
    width: 95%;
    text-align: center;
    background: #1f1f31;
    color: #dcd7f6;
    border: 2px solid #8c52ff;
    padding: 15px;
    border-radius: 10px;
  
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    /* General Page Styling */
.airdrop-page {
      font-family: 'Arial', sans-serif;
      padding-bottom: 170px;
    }
    .title {
      font-size: 2rem;
    }
    .connect-wallet-btn {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
    .cex-partners-grid {
      gap: 10px;
    }
  }
  

  .claimable{
    background: url('../assets//images/bgt.png');
    box-shadow: 2px 2px 2px 2px #8c52ff;
    background-color: #000;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
  }