.filter-bar {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .filter-bar button {
    background-color: #333;
    color: white;
    border: 1px solid #555;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .filter-bar button:hover {
    background-color: #555;
  }
  
  .filter-bar button.active {
    background-color: #4caf50;
    border-color: #4caf50;
  }
  
  .marketplace-container .nft-grid {
    margin-top: 30px;
  }



  .marketplace-container {
    text-align: center;
    background-color: #1a1a1a;
    color: #fff;
    padding: 20px;
  }
  
  .nft-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .nft-card {
    background-color: #2c2c2c;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
  }
  
  .nft-card:hover {
    transform: scale(1.05);
  }
  
  .nft-card h3 {
    margin-bottom: 10px;
  }
  
  .nft-card button {
    background-color: #a837e9;
    color: white;
    border: none;
    display: block;
    margin: 0 auto;
    width: 90%;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .nft-card button:hover {
    background-color: #7b17b4;
  }
  
  .nft-card.common {
    border-left: 5px solid #00bcd4;
  }
  
  .nft-card.epic {
    border-left: 5px solid #9c27b0;
  }
  
  .nft-card.legendary {
    border-left: 5px solid #fbc02d;
  }
  
  @media (max-width:767px) {
    .nft-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 2fr));
        gap: 6px;
        margin-top: 20px;
      }

      .nft-card {
        background-color: #2c2c2c;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        font-size: 0.6rem;
        transition: transform 0.2s;
      }
      
      .nft-card h3 {
        margin-bottom: 10px;
        font-size: 1rem;
      }

      .nft-card button {
        background-color: #a837e9;
        color: white;
        border: none;
        display: block;
        margin: 0 auto;
        width: 90%;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 10px;
        transition: background-color 0.3s;
      }
      
    
  }