/* src/components/Loader.css */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh; /* Full screen height */
  background-color: rgba(0, 0, 0, 0.692); /* Semi-transparent background */
   /* Ensure it covers the whole screen */
  top: 0;
  left: 0;
  z-index: 9999; /* Make sure it overlays other content */
}

.loader-logo {
  width: 120px;
  height: 100px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
