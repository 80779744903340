/* src/components/Profile/Profile.css */

.profile-container {
    padding: 20px;
    width: 100%;
    height: 100vh;
    background: black;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  p {
    font-size: 18px;
  }
  
  .logout-btn {
    margin-top: 33vh;
    width:100%;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: none;
    background: rgb(163, 5, 236);
    border-radius: 14px;
  }

  .logout-btn:hover{
    background: rgb(118, 3, 153);
    color: black;
  }

  .span{
    text-align: end;
    float: right;
    margin-right: 2%;
  }
  .user{
    font-size: 2.3rem;
  }