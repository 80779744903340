.auth-container{
    margin: 20px auto;
    width: 80%;
    color: white;
}


.auth{
   
    display: flex;
}

.auth-h2{
    flex: 1;
    display: flex;
    height: 100%;
    align-items: center;
}

.auth-h2 h2{
    font-size: 3rem;
    margin-top: 20px;
 
 
}

.auth-img{
    flex: 1;
}



.auth-btn{
    background: rgb(141, 11, 228);
    width: 60%;
    padding: 10px;
    border-radius: 12px;
    border: none;
    margin: 10px auto;
}

.auth-btn:hover{
    background: rgb(98, 2, 122);
}

@media (max-width:992px) {
    .auth-container{
        margin: 20px auto;
        width: 100%;
    }

    .auth-h2 h2{
        font-size: 1.2rem;
    }

    .auth-btn{
     
        width: 100%;
    
    }
    
    
}