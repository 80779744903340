/* src/components/NotificationCenter.css */
.notification-center {
    top: 0;
    right: 0;
    margin: 20px;
    height: 100vh;
    width: 100%;
  }
  
  .notifications {
    background-color: #333;
    color: white;
    margin: 0 auto;
    margin-bottom: 10px;
    border-radius: 5px;
    width:100%;
  }
  
  .notifications p {
    margin: 0;
  }
  
  .notifications button {
    margin-top: 5px;
    background-color: #ff5252;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .admin-panel {
    margin-top: 20px;
  }
  
  .admin-panel button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .add-notification {
    display: flex;
    flex-direction: column;
  }
  
  .add-notification input {
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
  }
  
  .add-notification button {
    background-color: #28a745;
  }
  