@import url("https://fonts.googleapis.com/css2?family=Bokor&display=swap" );

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  background: #fffed2;
  color: black;
  overflow-x: hidden;
    font-family: "Bokor", system-ui;
    font-weight: 400;
    font-style: normal;

  
  
}

.body{
  background-color: #111111;
  height: 100%;
  color: white;
  width: 100%;
}

.body2{
  background: #111111;
}
.containerr{
  width: 100%;
  position: fixed;
	padding-right:15px;
	padding-left:15px;
	margin-right:auto;
	margin-left:auto;
  color: white;
  height: 70px;
  top: 0;
  align-items: center;
  z-index: 10000;
  background: black;
}


.sec__one {
    position: relative;
    width: 100%;
    display: flex;
    padding: 60px 0;
}
.sec__one h1{
  text-align: center;
  display: block;
  margin: 0 auto;
  color: #07a8ff;
  font-family: 'Inter', sans-serif;
}
.navBar .logo img {
  float: left;
  max-height: 120px;
}
.is-active {
  color: #ffffff!important;
}

.navBar .col-md-3 {
  z-index: 7;
}
ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}
ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 23px;
}
li {
  list-style-type: none;
}
.menu-item a {
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  position: relative;
  display: block;
  color: #ffffff;
  margin: 27px 17px;
  text-decoration: none;
  cursor: pointer;
}
.header__middle__logo{
  width: 15%;
  display: inline-block;
  float: left;
}
.header__middle__menus {
  width: 65%;
  display: inline-block;
  float: right;
  margin-top: -17px;
}

.header__middle__login{
  width: 20%;
  display: inline-block;
  float: left;
}
.menu-item.menu__qqqitem a{
  margin: 27px 10px;
}
.header__middle__search {
  width: 7%;
  display: inline-block;
  float: right;
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}
.header__middle {
  display: flex;
  width: 100%;
  float: left;
  height: 70px;
  
  position: relative;
  justify-content: center;
}
.header__middle__logo > a > h1 {
  color: #07a8ff;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
}
 .active{
  color: #64038a!important;
  text-decoration: none;
} 
.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: #ff1800;
}
.sub__menus__arrows{
  position: relative;
}
.sub__menus__arrows svg, .sub__menus__arrows__full svg{
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  display: none;
  background: rgb(0, 0, 0);
  border: 1px solid lavender;
  width: 227px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}

@media (max-width:767px) { 
  .header__middle .active {color: #ffffff!important;}
}

@media (min-width:992px) { 
  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus  a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
}
.menu-item:hover > a{
  color: #b907ff !important;
  text-decoration: none;
}
.menu-item .sub__menus li:hover a{
  color: #b007ff !important;
  text-decoration: none;
}

.header__middle__logo img{
  max-width: 80px;
  margin: 5px 0 0 0;
}


@media(max-width:991px){
.header__middle__logo {width: 50%; margin-top: -10px;}
.header__middle__menus {width: 15%; margin-top: -10px; float: right; margin-right: 2px;}
.header__middle__login{width: 20%; float: left;}
.header__middle__logo img {max-width: 50px;margin: 27px 0 0 0;}
.main-nav .menubar__button:hover {background-color: #f44336;}
.main-nav .menu-item:hover > a {color: #07a8ff !important}
.main-nav .menubar__button {
  display: block!important;
  float: right;
  background-color: #080808;
  color: #fff;
  padding: 6px 7px;
  border-radius: 5px;
  margin: 27px auto 0;
  cursor: pointer;
  position: relative;
  z-index: 10037;
}
.main-nav .menubar__button svg{font-size: 27px;}
.main-nav ul.menuq2 {
  display: block!important;
  position: absolute;
  left: 0;
  z-index: 10007;
  background-color: #000000;
  border-bottom: 3px solid #ff3d00;
  right: 0;
  height: 100Vh;
  padding-bottom: 17px;
  padding-top: 57px;
  padding: 10px;
}
.main-nav .menu-item a {margin: 10px 17px; padding:10px; text-align: left;color: #b10bc0;}
.main-nav  ul.main-menu {display: none}
.main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active2{display: block!important;}
.menus__categorysss{display: none;}
.menus__categorysss2{display: none;}
.menu-item .sub__menus__full a {text-align: left;}

}


/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px; /* Width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: black; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(141, 11, 228); /* Color of the scroll thumb */
  border-radius: 10px; /* Rounded corners of the scroll thumb */
  border: 2px solid black;
  /* Padding around the scroll thumb */
}

@media (max-width:992px) {

  /* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px; /* Width of the entire scrollbar */
  display: none;
}
  
}










.main-container {
  display: flex;
}
.main {
  padding: 10px;
  margin-top: 30px;
  width: 100%;
  height: 100vh;
  color: #fff;
position: fixed;
  background-color: rgb(29, 28, 28);
  background-image: url("./assets/images/back.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: fixed;
  overflow: hidden;
  

}

img{
  width: 100%;
}

@media (max-width:769px) {
  .main-container {
    display: none;
  }
  .main {
    padding: 0;
    width: 100%;
    margin-left:0;
    height: 100vh;
  }
  
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
  
}

/* Sidebar */
.sidebar {
  background:  rgb(141, 11, 228);
  color: white;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  z-index: 1000;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}
.logo {
  font-size: 18px;
  line-height: 0;
}
.bars {
  width: 30px;
}
.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);
  color: white;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link:hover {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.active {
  
}
.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: white;

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}

@media (max-width:767px) {
  .sidebar {
    background:  rgb(141, 11, 228);;
    color: white;
    height: 100vh;
    overflow-y: auto;
    z-index: 1000;
  display: none;
  }
  
}

/* src/components/Wallet/Wallet.css */
/* Wallet.css */

.wallet-container {
  background-color: #070707;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 85vh;
  margin: auto;
  text-align: center;
}

.wallet-address {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.wallet-address p {
  margin: 0;
  font-size: 0.9rem;
  word-break: break-all;
  color: white;
}


.wallet-balance {
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
margin-top: 0;
background: #000;
padding:  10px;
padding-top: 20px;

}

.wallet-balance h2 {
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #ffffff;
}

.wallet-actions {
  display: flex;
  justify-content: space-around;
}

.wallet-btn1 {
  display: flex;
  width: 20px;
  align-items: center;
  gap: 5px;
  font-size: 0.6rem;
  padding: 5px 5px;
  background-color: rgb(141, 11, 228);
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.wallet-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1rem;
  padding: 10px 20px;
  background-color: rgb(141, 11, 228);
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.wallet-btn:hover {
  background-color: rgb(72, 2, 119);
}


.tablet{
  background: rgba(139, 38, 38, 0.5);
  width: 100%;
  height: 90vh;
  position: fixed;
  overflow: auto;
  margin-left: 0;
  left: 0;
  margin-bottom: 10px;


}

.tab-content {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.651);
  border-radius: 5px;
  height: 100%;
  border: none;
  
}

h3 {
  margin-bottom: 20px;
}

p {
  font-size: 18px;
}

.transfer-btn{
  display: flex;
  width: 100%;
  justify-content: space-between;
  background:  rgb(141, 11, 228);;
}

.btn{
  flex: 1;
  margin: 0 10px;
}

/* src/components/ImageUpload/ImageUpload.css */

.image-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.image-preview {
  margin-top: 10px;
}

.image-preview img {
  max-width: 100%;
  max-height: 200px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.home-grid{
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  top: 100px;
  text-align: center;
  left:0 ;

}

.grid{
  flex: 1;
  border-radius: 20px;
padding: 10px;
  text-align: center;

  justify-content: center;
  align-items: center;
  color: #fff;

}

.grid p{
  font-size: 1.8rem;
  font-size: bold;
}


@media (max-width:992px) {
  .grid h4{
    font-size: 1rem;
  }
  
}


.task-center{
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  
}

.task-center div{
  flex: 1;
}


.bonus-container{
  text-align: center;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;

}

.bonus-container p {
  margin-top: 50px;
}

.bonus-container img{
  width: 400px;
  height: 350px;
  float: right;
  margin: 20px;
}


@media (max-width:992px) {
  .bonus-container{
    text-align: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }
  
  .bonus-container p {
    margin-top: 50px;
  }
  
  .bonus-container img{
    width: 100%;
    height: auto;
    float: none;
    margin: 20px;
  }
  
}

.time{
  text-align: center;
  font-size: 3rem;
  color: red;
}

.claim-bonus-btn{
  width: 90%;
  display: block;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  border-radius: 20px;
}


.modal-popup{
  background: #000000;
  color: white;
}

.dash{
  height: 100%;
  width: 100%;
 

}

.announce{
  float: left;
}


.news{
  border-bottom: 1px solid rgb(75, 1, 124);
  padding: 10px;
  margin: 5px 0;
  margin-bottom: 20px;
  width: 90%;
  box-shadow: 1px 1px 1px 1px black;
}


.announcements-container{
  color: #fff;
  background: #222222;
  height: 100vh;
  overflow: auto;
  margin-bottom: 10px;
}

h5{text-align: center;}

.announcement-list{
  width: 100%;
  list-style: none;
  padding: 10px;
  border-top: #000 solid 1px;
  border-bottom: #000 solid 1px;
}

.announcement-list li::marker{
  content: "📢";
}



.linked{
  text-decoration: none;
  color: white;
}

.linked h3{
  font-size: 1.3rem;
}

.announcement-details{
  height: 100vh;
  width: 100%;
  overflow: auto;
  padding: 10px;
  background: #000000a9;
  left: 0;
}

.linked:hover{
  color: #000000;
  text-decoration: none;
}


button{
  background: rgb(141, 11, 228);
  border: none;
  padding: 10px;
  justify-content: center;
  border-radius: 50px;
  color: white;
  display: block;
  margin: 0 auto;
  width: 45%;
  text-align: center;
}

.button1{
  background: rgb(141, 11, 228);
  border: none;
  padding: 10px;
  justify-content: center;
  border-radius: 50px;
  color: white;
  display: block;
  margin: 0 auto;
  width: 50%;
  text-align: center;
}

button:hover{
  background: rgb(67, 1, 110);
  text-decoration: none;
}

/* Market.css */

.custom-tab-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  border-radius: 20px;

  

}

.custom-tab-button {
  background-color: rgb(0, 0, 0);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  color: #fff;
  border-radius: 20px;

}

.custom-tab-button.active {
  background-color: rgb(141, 11, 228);
  color: #fff;
}

.custom-tab-button:hover {
  background-color: rgb(141, 11, 228);
  color: #fff;
  text-decoration: none;
}

.custom-tab-content {
  border: 1px solid #ddd;
  padding: 20px;
  background-color: #000000;
  border-radius: 20px;
}



.market{
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background: url('./assets/images/tokenbg.png');
  background-position: center;
  background-size: contain;
    color: #dcd7f6;
    background-image: linear-gradient(180deg, #1e1e2f, #000);
  padding-bottom: 70px;
  margin: 0 auto;
  margin-bottom: 0;
  position: fixed;
}


/* TaskCenter.css */

.task-center {
 width: 100%;
  background-color: #000000;
  height: 60vh;
  overflow: auto;

}

.task-list {
  margin-bottom: 20px;
}

.task-item {
  display: block;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  width: 80%;
  margin: 0 auto;
  min-width: 300px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #1f1e1e;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.task-item:hover {
  background-color: rgb(141, 11, 228);
}

.task-item.completed {
  background-color: #1f1e1e;
}

.task-actions {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.task-actions button {
  flex-shrink: 0;
  flex: 1;
}

.modal-popup {
  text-align: center;
}

.custom-modal .modal-content {
  background-color: #000;
  color: #fff;
}

.custom-modal .modal-header,
.custom-modal .modal-footer {
  border-color: #444;
}

.custom-modal .modal-header .close {
  color: #fff;
}

.custom-modal .modal-body {
  color: #fff;
}


/* ReceivePoints.css */

.modal-content {
  background-color: black !important;
  color: white;
}

.modal-header,
.modal-footer {
  border-color: black;
}



/* src/components/Referral/ReferralPage.css */

.referral-container {
  max-width: 800px;
  margin: 0 auto;
 height: 100vh;
  padding: 20px;
  padding-top: 100px;
  border-radius: 10px;
  box-shadow: 4px 4px 4px 4px black;
  background-image: url("./assets/images/back.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  
}

@media (max-width:992px) {
  .referral-container {
   width: 100%;

    margin: 0 auto;
    padding: 5px;
    overflow: auto;
    padding-top: 100px;
    border-radius: 10px;
    box-shadow: 4px 4px 4px 4px black;
    background-image: url("./assets/images/back.jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
  

  }
  
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #ffffff;
}

.referral-section {
  margin-bottom: 15px;
}


.referral-section img{
  background: black;
  margin-bottom: 20px;
}

.referral-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
}

.referral-buttons{
  display: flex;
  flex-direction: row;
  width: 100%;

}

.referral-buttons button{
  flex: 1;
  margin: 10px;
  background: rgb(0, 0, 0);
  border: none;
  border-radius: 20px;
} 

.referral-bonuses {
  display: flex;
  margin-bottom: 20px;
  overflow-x: auto;  /* Enable horizontal scrolling */
  width: 100%;
  gap: 10px;  /* Adds space between buttons */
}

.referral-bonuses button {
  border-radius: 20px;
  margin: 10px;
  flex: 0 0 120px;  /* Buttons will not shrink or grow, width is set to 120px */
  padding: 10px;
  text-align: center; /* Align text in the center */
  white-space: nowrap;  /* Prevent button text from wrapping */
}



.copy-icon {
  cursor: pointer;
  color: #007bff;
  margin-left: 10px;
}

.copy-icon:hover {
  color: #0056b3;
}

.loading {
  text-align: center;
  padding: 50px;
  font-size: 18px;
  color: #666;
}

.share-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #8c00ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.share-button:hover {
  background-color: rgb(87, 3, 143);

}

.container{
  height: 100%;
  width: 100%;
  margin: 100px auto;
}




form{
  max-width: 600px;
  margin: 0 auto;
  display: block;
}

/* Navbar.css */
.navbar-home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  height: 80px;
  background-color: #0000000c;
  border-bottom: 1px solid #e7e7e7;
}

.navbar-logo-home a {
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: #ffffff;
}
.navbar-logo-home img{
  width: 80px;
}

.navbar-links a {
  text-decoration: none;
}

.navbar-links-home button {
  padding: 8px 16px;
  font-size: 16px;
  color: rgb(255, 255, 255);
  padding: 8px;
  border: 1px solid rgb(141, 11, 228);
  width: auto;
  background: #000;
 border-top-left-radius: 30px;
 border-bottom-right-radius: 30px;

  width: 100px;
  font-size: 1.1rem;
}

.navbar-links-home button:hover{
  text-decoration: none;
}


.home-container{
  display: flex;
  width: 100%;
  top: 0;
  margin-top: -50px;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.home-contain{

  padding: 20px;
  margin: 0 auto;
  text-align: center;
}


/* Footer.css */
.footer {
  background-color: #0c0b0b;
  padding: 30px 5px;
  margin-top: 0;
  padding-bottom: 0;
  border-top: 1px solid #e7e7e7;
  font-family: Arial, sans-serif;
  color: white;


}

.footer-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 95%;
  margin: 0 auto;
  margin-left: 5%;

}



.footer-section {
  flex: 1;
  margin: 20px 0;
  min-width: 300px;
}

.footer-section h4 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
}

.footer-section p {
  margin: 0;
  font-size: 14px;
}

.footer-section a {
  text-decoration: none;
  color: #007bff;
  font-size: 14px;
}

.footer-section a:hover {
  text-decoration: none;
}

.footer-socials a {
  margin: 0 10px;
  font-size: 18px;
  color: white;
}

.footer-socials a:hover {
  color: rgb(141, 11, 228);
}

.footer-bottom {
  text-align: center;
  padding: 10px 0;
  background-color: #030303;
  padding-bottom: 60px;
  border-top: 1px solid #ced4da;
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
}




/* NFTComponent.css */

.nft-container {
  position: relative;
  width: 100%;
  height: 110vh; /* Adjust the height as needed */
  overflow: hidden;
  top: 0;
  margin-top: -5px;
  border-bottom: white 2px solid;
}
.nft-images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110vh; 
  background-position: center;
  /* Double the width to accommodate two images for sliding */
 /* Adjust the animation duration as needed */
}

.nft-images img{
  
}

@media (max-width:769px) {
  .nft-container {
    position: relative;
    width: 100%;
    height: 92vh; /* Adjust the height as needed */
    overflow: hidden;
    top: 0;
    margin-top: -5px;
  }
  .nft-images {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 280%; /* Double the width to accommodate two images for sliding */
    animation: slide 10s linear infinite; /* Adjust the animation duration as needed */

}
}







@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Style for the text/content */
.nft-content {
  position: absolute;
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.nft-content h1{
  max-width: 600px;
}
.nft-content button{
  width: 200px;
}



.signup{
  height: 100Vh;
  display: block;
  justify-content: center;
  width: 100%;
  background-color: #000;
  position: fixed;

}

.signup form, h2{
  margin-top: 100px;
  width: 90%;
}
/* StakingComponent.css */


.privacy{
  width: 100%;
  padding: 20px;
  background: #000;

}

.link-state{
  text-decoration: none;
  color: white;
}

.sub{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  padding: 0;
}
.sub-textarea{
  flex: 3;
}

.sub-button{

  padding: 5px;
  flex: 2;
}

.message{
  width: 100%;
}

.msg{
  width: 80%;
}



.sub form{
  font-size: 1rem;
}

.twitter{
  width: 70px;
}

.transfer{
  width: 100%;
  height: 90vh;
  text-align: center;
}

.last-time{
  color: #000000;
  font-size: 0.9rem;
  padding-top: -10px;
}



.farm-component {
  display: flex;
  max-width: 600px;
  margin: 0 auto;
  min-height: 85vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: url('./assets/images/studio-background-concept-abstract-empty-light-gradient-purple-studio-room-background-product-plain-studio-background.jpg');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: fixed;
 /* Light gray background */
  border: 1px solid #dee2e6; /* Border color */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 4px 4px 4px 4px rgb(0, 0, 0); 
  margin-bottom: 20px;/* Subtle shadow */
  overflow: hidden;
}

.farm-component img {
  max-width: 100%;
  height: auto;
  border-radius: 8px; /* Rounded corners for the image */
}

.img-fluid{
  width: 500px;
  animation: linear infinite 3s;

}



.img-fluid

.farm-component .btn {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  height: 50px;
}


.toggle{
  display: flex;
  background: black;
  justify-content: center;
  align-items: center;
}

.toggle button{
  height: 20px;
  width: 100px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}


/* App.css */
.cube-container {
  perspective: 1000px;
}

.cube {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 1s;
}

.cube-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.cube-face-front {
  transform: rotateY(0deg) translateZ(50px);
}

.cube-face-back {
  transform: rotateY(180deg) translateZ(50px);
}


.header{
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  margin-bottom: 10px;
}

.farmed-button{
  width: 90%;
  height: 50px;
  box-shadow: 4px 4px 4px 4px rgb(54, 3, 54);
}

.farm-stats{
  display: flex;
  justify-content: center;
  width: 100%;

}

.farm-stats p{
  margin: 5%;
  background: rgb(141, 11, 228);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 4px 4px 4px 4px purple;

}

/* FarmComponent.css */
.spinning {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.verify{
  height: 80vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

}

.verify button{
  width: 90%;
  border-radius: 10px;
  padding: 10px;
}

.avaliable{
  display: flex;
  justify-content: center;
  width: 100%;
  color: rgb(255, 255, 255);
  align-items: center;
  font-size: 20px;
  background: rgb(141, 11, 228);

}
.avaliable span{
  margin-left: 10px;
}

.avaliable button{
background: #000;
font-size: 12px;
width: auto;
padding: 10px;
margin: 0 20px;
border-radius: 2px;
text-decoration: underline;
}


.whitepaper{
  width: 100%;
  background: rgb(141, 11, 228);

  border-radius: 10px;
  text-align: left;
  height: 70px;
}

.whitepaper div{
  float:left;
  font-size: 25px;
  background: black;
  padding: 10px;
  align-items: center;
  border-radius: 5px;
  margin:5px 10px;
}

.whitepaper h3{
  font-size: 1.2rem;
  padding: 5px;
}

.whitepaper p{
  line-height: 0;
}




@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader-contain{
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.loader {
  border: 5px solid #000000; /* Light grey */
  border-top: 5px solid #c834db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.loader-text {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}


.my-nft{
  min-height: 20vh;
  position: relative;
  max-width: 600vh;margin: 0 auto;
  backdrop-filter: blur(20px) saturate(180%);
    -webkit-backdrop-filter: blur(20px) saturate(180%);
    background-color: rgba(0, 0, 1, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    box-shadow: 4px 4px 4px 4px rgb(35, 0, 58);
  padding: 10px;
  margin-top: 15px;
}

.nft-img{
  max-width: 400px;
  float: left;
}

.hexac{
  width: 250px;
  display: block;
  margin: 0 auto;
}

@media (max-width:767px) {
  .nft-img{
    max-width: 100px;
    float: left;
    margin-right: 10px;
    margin-top: 10px;
  }
}

.my-nft button{
  width: 90%;
  margin: 0 auto;
  display:block;
  border-radius: 15px;
}

.info{
  float: right;
  font-size: 2rem;
  margin: 20px;
}
.info-p{
  text-align: center;
  font-size: 1.3rem;
  padding: 20px;
}

.home{
  width: 100%;
  margin: 0 auto;
  padding-bottom: 100px;
}


section{
  padding: 10px;
}

.home-grid, .home-grid2{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  flex-direction: row;
}

.home-grid li{
  width: 30%;
  padding: 10px;
 
  border-radius: 10px;
  margin: 1%;
}

.home-grid2  li img{
  width: 200px;
  height: 250px;
}

.home-grid li img{
  width: 70px;
}

.home-grid2 li{
  width: 46%; 
  padding: 10px;
  background-color: rgba(46, 45, 45, 0.781);
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 2px 2px 2px 2px  rgb(252, 136, 4);
  margin: 2%;
}


.home-grid2 .group{
  width: 46%; 
  background-color: rgba(46, 45, 45, 0.781);
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 2px 2px 2px 2px  rgb(252, 136, 4);
  margin: 2%;
  text-decoration: none;
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.home-grid2 .group:hover {
  transform: scale(1.05);
  text-decoration: none;
  box-shadow: none;
  color: white;
}

.home-grid2 .group .img{
  width: 100%;
  display: block;
  background: rgba(3, 247, 255, 0.171);
  border-bottom: 1px solid white;
}

.home-grid2 .group img{
  width: 600px;
  height: 400px;
  display: block;
  margin: 0 auto;
}

.group p {
  padding: 10px;
  margin: 10px;
}

.linkstyle{
  color: white;
  text-decoration: none;
}

.linkstyle:hover{
  color: white;
  text-decoration: none;
}
strong{
  display: block;
  margin: 10px;
}
.advanced-feature{
  background: url('./assets/images/dcube.jpg');
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.node-infrastructure{
  background: url('./assets/images/cubebg.jpg');
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (max-width:998px) {

  .home{
    width: 100%;
  }
  .home-grid li{
    width: 100%;
    margin: 5% 0;
   
  }
  .home-grid2 li{
    width: 100%;
    margin: 5% 0;
   
  }

  .home-grid2 .group{
    width: 100%;
    margin: 5% 0;
  }
  .home-grid2 .group img{
    width: 250px;
    height: 250px;
    display: block;
    margin: 0 auto;
  }
}

.introduction, .challenges,.consensus, .performance, .gaming{
display: flex;
max-height: 900px;
justify-content: center;
align-items: center;
border-bottom: 2px solid white;
}

.introduction, .challenges, .consensus, .performance, .gaming div{
  flex: 1;
}
section img{
  width: 700px;
}

.imageb{
  width: 450px;
  float: right;
 
  margin-right: 5%;
}
.imaged{
  width: 350px;
  float: left;
  margin: 50px ;
 
  margin-left: 5%;
}

.mobile{
  display: none;
}

.introduction, .intro{
  background-color: #444444;
  border-bottom: 2px solid white;
}

.challenges{
  background-color: #b97610;
}

.performance{
  background-color: #1e112c;
}

.consensus{
  background-color: rgba(0, 0, 0, 0.932);
}

.gaming{
  background-color: #027eb8;
}

.button:hover{
  text-decoration: none;
}


@media (max-width:767px) {
  .introduction, .challenges,.consensus, .performance, .gaming{
    display: flex;
    height: 100%;
    min-height: 90Vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 18px;
    }
  section img{
    width: 280px;
  }
  .desktop{
    display: none;
  }
  .mobile{
    display: block;
  }
  .imageb{
    width: 300px;
    float: none;
   
    margin:0 auto;
  }
  .imaged{
    width: 300px;
    float: none;
    margin: 0 auto;
  }
  
}


/* App.css or styles.css */

/* Dark theme card styles */
.card-dark {
  background-color: #1e1e1e; /* Dark background color */
  border: 1px solid #333; /* Slightly lighter border color */
  color: #f0f0f0;
  justify-content: center; 
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;/* Light text color */
}

.card-dark .card-header {
  background-color: #333; /* Darker header background */
  color: #f0f0f0; /* Light header text color */
}

.card-dark .card-body {
  background-color: #000000; /* Dark background color for body */
}

.card-dark .card-footer {
  background-color: #333; /* Dark footer background */
  color: #f0f0f0; /* Light footer text color */
}




.box{
  margin-top: -350px;
}


@media (max-width:767px) {
  .box{
    margin-top: -200px;
  }
  
}

.egg{
  width: 250px;
  height: 250px;
  display: block;
  margin: 0 auto;
  
}

.egg2{
  width: 500px;
  display: block;
  margin: 0 auto;
}

.icons{
  width: 70px;
  height: 70px;

}


.countdown-banner {
  background-color: #000; /* Black background */
  color: #fff; /* White text for contrast */
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  margin: 20px 0;
}

.banner-content{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.banner-content h1 {
  font-size: 2rem;
  color: #a020f0; /* Purple text */
  margin-bottom: 10px;
}

.banner-content p {
  font-size: 1.2rem;
  color: #dcdcdc; /* Light gray text for readability */
  margin-bottom: 5px;
}

.banner-content h2 {
  font-size: 2.5rem;
  color: #a020f0; /* Purple text */
  margin-top: 10px;
}

.countdown-timer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.countdown-unit {
  margin: 0 10px;
  animation: fadeIn 1s ease-in-out;
}

.countdown-value {
  font-size: 2rem;
  color: #a020f0; /* Purple text */
  display: block;
}

.countdown-label {
  font-size: 1rem;
  color: #dcdcdc; /* Light gray text for readability */
}

.phase {
  font-size: 1.5rem;
  color: #dcdcdc; /* Light gray text for readability */
  margin-top: 10px;
  font-weight: bold;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width:767px) {
  .banner-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
}


.hexa-container {
  position: relative;
  width: 100%;
  height: 350px;
  border-radius: 20px;
  background-image: url('./assets//images/opensea.jpg'); /* Replace with the actual image path */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 20px;
}

.title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.description1 {
  font-size: 1.5rem;
  margin-top: 0;
}



@media (max-width:767px) {

  
  .hexa-container {
    position: relative;
    width: 100%;
    height: 250px;
    border-radius: 20px;
    background-image: url('./assets//images/opensea.jpg'); /* Replace with the actual image path */
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.705); /* Black overlay with 50% opacity */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding: 20px;
    border-radius: 20px;
  }
  
  .title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .description1 {
    font-size: 1.2rem;
    margin-top: 0;
  }
  
}

.btn{
  width: 100px;
  text-align: center;
  background: rgb(100, 11, 160);
  color: #080808;
}

.logob{
  width: 50px;
}


