/* src/components/Navbar.css */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    z-index: 999; /* Make sure the nav is always on top */
    padding: 0 20px;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    flex: 1;
    text-align: center;
    color: white;
  }
  
  .notification {
    position: absolute;
    right: 20px;
    font-size: 20px;
    color: white;
  }

  .profile {
    position: absolute;
    display: none;
    left: 20px;
    font-size: 20px;
    color: white;
  }

  @media (max-width:992px) {
    .navbar {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        z-index: 999; /* Make sure the nav is always on top */
        padding: 0 20px;
      }
    .profile {
        position: absolute;
        display: flex;
        left: 20px;
        font-size: 30px;
      }
    
  }
  