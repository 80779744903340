.bottom-nav {
  position: fixed;
  bottom: 7px;
  left: 0;
  right: 0;
  background-color: #231527;
  justify-content: space-around;
  padding: 10px 0;
  margin: 0 auto;
  z-index: 1000;
  border-radius: 10px;
  display: none;
  width: 95%;
}

.bottom-nav a {
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  flex: 1;
  font-size: 1.3rem;
}

.bottom-nav a:hover {
  color: rgb(141, 11, 228);
  background: transparent;
  text-decoration: none;
}

.bottom-nav a span {
  display: block;
  font-size: 12px;
}

.bottom-nav a.active2 {
  color: rgb(0, 0, 0);
  background: transparent;
  text-decoration: none;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .bottom-nav {
    display: flex;
  }
}
