.bonus-container {
  text-align: center;
  padding: 20px;
  position: relative; /* For positioning the claim button */
  overflow-y: auto;
  padding-bottom: 120px;
  

}

.streak-container {
  margin-top: 20px; 
  padding-bottom: 70px;/* Added padding for space between the content and the button */
}

.streak-grid {

  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns on larger screens */
  gap: 10px;
  margin: 0 auto;
  max-width: 1000px; /* Restrict max width */
  grid-auto-rows: 100px; /* Ensure consistent row height for smaller screens */
  margin-top: 50px;
}

.streak-item {
  border: 1px solid #ffffff;
  padding: 5px;  /* Reduced padding */
  border-radius: 5px;
  background-image: url('../../assets/images/token.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  color: white;
  text-align: center;
  position: relative;
  min-height: 100px; /* Reduced minimum height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 2px 2px rgb(77, 0, 128);
}

.streak-item img {
  width: 40px;  /* Reduced image size */
  height: 40px;
  margin-bottom: 5px;  /* Reduced space between image and text */
  position: relative;
}

.streak-item span {
  display: block;
  font-size: 14px;
  text-align: left;
width: 100%;
  color: rgb(255, 255, 255);
  font-size: 1rem;
  font-weight: bold;
 
}

.rw {
  display: flex;
  align-items: center;
  gap: 5px;
}

.rw img {
  width: 30px;
  height: 30px;
  display: block;
  margin: 0 auto;
  position: relative;
}

.streak-item.achieved {
  background-color: rgba(90, 7, 110, 0.548);
  color: #000;
}




/* Button positioning */
.claim-bonus-btn {
 display: block;
 margin: 50px auto;  bottom: 20px; /* Position the button 20px from the bottom */
  padding: 10px 20px;
  background-color: #a226db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  z-index: 10; /* Ensures the button is always on top */
}

.claim-bonus-btn:hover {
  background-color: #7445a0;
}

.time {
  font-weight: bold;
}

@media (max-width: 769px) {
  .streak-grid {
    grid-template-columns: repeat(3, 2fr); /* 3 columns on medium screens */
  }

  .streak-item img {
    width: 35px;  /* Slightly smaller image size on smaller screens */
    height: 35px;
  }

  .claim-bonus-btn {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .streak-grid {
    grid-template-columns: repeat(3, 2fr); /* 2 columns on small screens */
  }

  .streak-item img {
    width: 30px;  /* Further reduce image size on very small screens */
    height: 30px;
  }

  .claim-bonus-btn {
    width: 100%; /* Full-width button on very small screens */
    bottom: 100px; /* Ensures the button is at the bottom of the screen */
  }

  .streak-item {
    border: 1px solid #ffffff;
    padding: 5px;  /* Reduced padding */
    border-radius: 5px;
    background: url('../../assets/images/token.png');
    background-position: center;
    background-size: contain;
    color: white;
    text-align: center;
    position: relative;
    height: 40px; /* Reduced minimum height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 2px 2px rgb(77, 0, 128);
  }

  .streak-item span {
    display: block;
    font-size: 14px;
    text-align: left;
  width: 100%;
    color: rgb(255, 255, 255);
    font-size: 0.8rem;
    font-weight: bold;
   
  }
}
