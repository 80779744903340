/* Web3AppFeatures.css */
.container2 {
    padding: 10px;
    font-family: Arial, sans-serif;
    overflow-x: hidden;
    padding-top: 60PX;
    margin-top: 50PX;
    width: 100%;
    background: rgb(0, 0, 0);
  }
  
  .section2 {
    margin-bottom: 50px;
    background-color: rgb(19, 18, 18);
    padding: 20px;
    border-radius: 20px;


  }
  
  .section-title {
    font-size: 1.5em;
    border-bottom: 1px solid #a72626;
    padding-bottom: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  
  .grid2 {
display: flex;
flex-grow: 200PX;
margin: 1%;
width: 100%;
justify-content: space-evenly;
overflow-x: auto;

  }

  .grid2-item:hover{
    background: #050505;
    color: rgb(218, 100, 32);
    cursor: pointer;
  }
  
  .grid2-item {
width: 250px;
height: 150px;
   justify-content: left;
    text-align: center;
    border: 1px solid purple;
    box-shadow: 2px 2px 2px 2px purple;
    background: #363434;
    padding: 20p;
    border-radius: 20PX;
    margin: 30px 10px;


  }
  .grid2-item span {
    text-align: center;
  }
  
  .grid2-item-icon {
text-align: center;
color: blueviolet;
font-size: 50px;
   

  }

  .grid2-item p{
    text-align: center;
    padding: 0 20px;
  }

  @keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slideOutToBottom {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(100%);
      opacity: 0;
    }
  }
  
  .modal.show .modal-dialog {
    animation: slideInFromTop 0.5s forwards;
  }
  
  .modal-exit .modal-dialog {
    animation: slideOutToBottom 0.5s forwards;
  }
  
  /* Center the modal */
  .modal.show.d-block {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

 
  .swap-container{
    background: #050505;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    color: white;
    flex-direction: column;
  }

  .swap-section{
    display: block;
    background: #363434;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
    padding: 10px;
    border-radius: 20px;
  }

  .swap-section input{
    background: #363434;
    border: 1px solid white;
    display: block;
    padding: 10px;
    border-radius: 5px;
    color: white;
  }

  .swap-section button{
    margin-top: 30px;
  }