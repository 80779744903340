.farm{
  background: url('../../assets/images/studio-background-concept-abstract-empty-light-gradient-purple-studio-room-background-product-plain-studio-background.jpg');
  width: 700px;
  margin: 0 auto;
 height: 100vh;
  background-attachment: fixed;
  padding: 20px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.farm-container {
    display: flex;
    flex-direction: column;
   justify-content: center;
   background: url('../../assets/images/mining2.png');

   align-self: center;
bottom: 200px;
min-height: 87vh;
    width: 700px;
    margin: 0 auto;
    background-attachment: fixed;
padding: 20px;
background-position: center 70%;
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 50px;


  }
  
  .spin{
    width: 400PX;
    height: 400PX;
    display: block;
    margin: 0 auto;
  }

  .farm-divs{
    position: fixed;
    top: 200px;
  }
  .farm-divs p{
    width: 110px;
    background: black;
    border-radius: 8px;
    text-align: center;
    padding: 10px;
    font-size: bold;
   
  }


  
  .farm-button {
    padding: 10px 20px;
    font-size: 1.2rem;
    font-weight: 900;
    width: 400px;
    justify-content: center;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 8px;
    display: block;
    margin: 0 auto;
    bottom: 60px;
    left: 500px;
    cursor: pointer;
    position: fixed;
    background-color: #60107085; /* Default background */
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .farm-button:disabled {
    cursor: not-allowed;
  }

  .x{
  flex: 1;
    font-size: 2rem;
    background-color: #ccc; /* Default background */
    border-radius: 8px;

  }
  


  @media (max-width:767px) {

    .farm-container {
        display: flex;
        flex-direction: column;
       justify-content: center;
       align-self: center;
       overflow-x: hidden;
    bottom: 200px;
        width: 100%;
        margin: 0 auto;
    padding: 20px;
    min-height: 100vh;
    padding-bottom: 100px;
    
      }

      .spin{
        width: 400PX;
   height: 400PX;
        display: block;
        margin: 0 auto;
      }
    
    .farm-button {
        padding: 10px 20px;
        font-size: 1.2rem;
        font-weight: 900;
  height: 50px;
  bottom: 90px;
        width: 90%;
        justify-content: center;
        color: rgb(0, 0, 0);
        border: none;
        border-radius: 8px;
        cursor: pointer;
        background-color: #60107085; /* Default background */
        transition: background-color 0.3s, transform 0.2s;
        left: 5%;
      }
      
  }

 
  
  .spinner {
    width: 400px;
    height: 400px;
    margin-top: 10px;
    display: block;
    margin: 0 auto;
  }
  
  .static {
    width: 3000px;
    height: 300px;
    margin-top: 10px;
    display: block;
    margin: 0 auto;

  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  