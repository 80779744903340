.task-center {
    height: 100vh;
    width: 100%;
      padding: 10px;
      margin: 0 auto;
      max-width:800px;
      justify-content: center;
      align-items: center;
      color: #000000;
      background: url('../assets/images/3d-shapes-glowing-with-bright-holographic-colors.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: rgb(0, 0, 0);
      padding-bottom: 120px;
   
  
    }

    .task-center h3 {
      padding-top: 100px;
      color: #ffffff;
    }
  
  .admin-controls, .tasks-list {
    margin-top: 10px;
    overflow-y: auto;
    border-top: 2px solid purple;
    display: block;
  }
  
  
  .task {
    display: flex;
    width: 600px;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    margin: 20px 0;
    background-color: rgb(186, 54, 204);
    box-shadow: 4px 4px 4px 4px black;
    border-radius: 10px;
    border-radius: 8px;
  }
  
  .task h4 {
    margin: 0;
    width: 75%;
    display: block;
  }
  .task span{
    font-size: 0.8rem;
    width: 200px;
    padding: 1px;
    margin-top: 200px;

  
  }
  
  .task button {
    padding: 10px 20px;
    width: 25%;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: #000000;
    cursor: pointer;
  }
  
  .task button:hover {
    background-color: #000000;
    color: #ffffff;
  }
  
  .admin-controls input {
    margin-right: 10px;
    padding: 8px;
    width: 80%;
    margin-left: 20px;
   
  }
  .admin-controls select{
    margin: 0 auto;
    margin-left: 20px;
  }
  
  .admin-controls button {
    margin-top: 10px;
  }
  

  @media (max-width:767px) {
    .task-center {
        height: 100vh;
        position: fixed;
        width: 100%;
          padding: 10px;
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          color: #000000;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          padding-bottom: 120px;
      }

      .task-center h3 {
        padding-top: 60px;
        color: #ffffff;
      }
      
    .task h4 {
        margin: 0;
        font-size: 0.8rem;
       
      }

      .task {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        margin: 20px 0;
        box-shadow: 4px 4px 4px 4px black;
        border-radius: 10px;
        border-radius: 8px;
      }

      .admin-controls, .tasks-list {
        margin-top: 10px;
        overflow-y: auto;
        border-top: 2px solid purple;
        padding-bottom: 50px;
      }
    
  }

  .task-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  