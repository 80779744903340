/* BellIcon.css */
.bell-icon-container {
    position: relative;
    display: inline-block;
    margin-right: 10px; /* Adjust as needed */
  }
  
  .bell-icon-container .badge {
    position: absolute;
    top: -5px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 10px;
  }
  
  .bell-icon-container a {
    color: inherit;
    text-decoration: none;
  }
  