.task-center-icon {
  position: relative; /* So the badge can be positioned relative to the icon */
  display: inline-block;
}

.task-center-icon .icon {
  font-size: 24px; /* Adjust icon size to fit in BottomNav */
  color: inherit; /* Inherit color from parent for consistency */
}

.task-center-icon .badge {
  background-color: red;
  color: white;
  font-size: 10px;
  border-radius: 50%;
  padding: 2px 6px;
  position: absolute;
  top: 5px;
  right: -5px;
  border: 1px solid white; /* Optional white border around the badge */
}
